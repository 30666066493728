@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
textarea:focus,
input:focus {
  outline: none;
}

.password-form {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.password-form .title {
  font-size: 2.0833333333vw;
  line-height: 1.7708333333vw;
  color: #084466;
  font-family: "NarkisBlock-Heavy", sans-serif;
}
@media only screen and (max-width: 767px) {
  .password-form .title {
    font-size: 5vw;
    line-height: 6.5625vw;
  }
}
.password-form .field {
  margin-top: 1.0416666667vw;
  font-size: 2.0833333333vw;
}
@media only screen and (max-width: 767px) {
  .password-form .field {
    margin-top: 1.5625vw;
    font-size: 4.6875vw;
  }
}
.password-form .field input {
  width: 34.375vw;
  height: 3.6458333333vw;
  padding: 0 0.5208333333vw;
  border-radius: 0.15625vw;
}
@media only screen and (max-width: 767px) {
  .password-form .field input {
    width: 71.875vw;
    height: 9.375vw;
    padding: 0 1.5625vw;
    border-radius: 0.46875vw;
  }
}
.password-form .submit {
  padding: 0.3645833333vw 0;
  margin-top: 1.0416666667vw;
  cursor: pointer;
  font-size: 1.5625vw;
  background-color: #ff212c;
  color: #fff;
  font-family: "NarkisBlock-Heavy", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .password-form .submit {
    padding: 1.09375vw 0;
    font-size: 3.75vw;
  }
}
.password-form .error {
  margin-top: 1.0416666667vw;
  color: red;
  font-size: 1.5625vw;
}
@media only screen and (max-width: 767px) {
  .password-form .error {
    margin-top: 1.5625vw;
    font-size: 3.59375vw;
  }
}