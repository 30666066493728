@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.header {
  position: relative;
  width: 100%;
  overflow-y: visible;
  background-color: #fff;
}
.header .header-wrapper {
  display: flex;
  width: 100%;
  height: 5.46875vw;
  padding: 0 2.2916666667vw;
  margin: 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  direction: rtl;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header .header-wrapper {
    height: 6.25vw;
  }
}
@media only screen and (max-width: 767px) {
  .header .header-wrapper {
    width: 100%;
    height: 10.9375vw;
    padding: 0 2.484375vw 0 3.59375vw;
    align-items: center;
  }
}

.left-side {
  position: absolute;
  top: 1.5625vw;
  left: 2.2916666667vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.left-side .socialls-wrapper {
  display: flex;
  align-items: center;
}

.center-logos {
  position: absolute;
  top: 1.0416666667vw;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, 0%);
}
@media only screen and (max-width: 767px) {
  .center-logos {
    top: 2.5vw;
    right: 2.1875vw;
    left: auto;
    transform: translate(0%, 0%);
  }
}
.center-logos .logo1 {
  width: 5.15625vw;
  margin-left: 4.21875vw;
}
@media only screen and (max-width: 767px) {
  .center-logos .logo1 {
    width: 9.734375vw;
    margin-left: 4.515625vw;
  }
}
.center-logos .logo2 {
  width: 4.21875vw;
  margin-left: 4.1666666667vw;
}
@media only screen and (max-width: 767px) {
  .center-logos .logo2 {
    width: 7.953125vw;
    margin-left: 4.34375vw;
  }
}
.center-logos .logo3 {
  width: 5.9895833333vw;
  margin-left: 4.21875vw;
}
@media only screen and (max-width: 767px) {
  .center-logos .logo3 {
    width: 10.875vw;
    margin-left: 4.5vw;
  }
}
.center-logos .logo4 {
  width: 6.875vw;
  margin-left: 4.2083333333vw;
}
@media only screen and (max-width: 767px) {
  .center-logos .logo4 {
    width: 12.65625vw;
    margin-left: 4.5vw;
  }
}
.center-logos .logo5 {
  width: 9.28125vw;
}
@media only screen and (max-width: 767px) {
  .center-logos .logo5 {
    width: 17.5vw;
  }
}
.center-logos img {
  display: block;
  width: 100%;
}