@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@media only screen and (max-width: 767px) {
  .date-picker .picker {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .date-picker .picker div {
    width: 100%;
  }
}
.date-picker .picker input {
  width: 13.0208333333vw;
  height: 2.4479166667vw;
  padding: 0.2604166667vw 0;
  font-size: 0.9375vw;
  font-family: "NarkisBlock-Medium", sans-serif;
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  border-radius: 0;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .date-picker .picker input {
    width: 100%;
    height: 12.5vw;
    padding: 0 0 0 1.875vw;
    line-height: 6.25vw;
    font-size: 4.21875vw;
  }
}
.date-picker .picker input::placeholder {
  color: #000;
}
.date-picker .picker i {
  display: none;
}