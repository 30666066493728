@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.not-started {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 88, 80, 0.86);
}
.not-started .not-started-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 29.4791666667vw;
  height: 13.6458333333vw;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: #000;
  font-size: 2.2395833333vw;
  line-height: 0.93;
}
@media only screen and (max-width: 767px) {
  .not-started .not-started-wrapper {
    width: 90%;
    height: 40.9375vw;
    max-width: 88.4375vw;
    font-size: 6.71875vw;
    line-height: 0.93;
  }
}
.not-started .not-started-wrapper p {
  text-align: center;
}
.not-started .not-started-wrapper p span {
  font-family: "NarkisBlock-Bold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .not-started .not-started-wrapper p span {
    display: block;
  }
}
.not-started .not-started-wrapper p b {
  font-weight: normal;
  font-family: "opensanshebreew_regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .not-started .not-started-wrapper p br {
    display: none;
  }
}
.not-started[class*=show] {
  display: block;
}