@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.select {
  width: 100%;
}

.select-field {
  position: relative;
  display: block;
  width: 100%;
  text-align: right;
}
.select-field :global .ant-select {
  display: block;
}
.select-field :global .ant-select-selection--single {
  height: 2.4322916667vw;
  padding: 0.2604166667vw 0vw 0.6770833333vw 0.5208333333vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .select-field :global .ant-select-selection--single {
    height: 2.3958333333vw;
    padding: 0.2604166667vw 0vw 0.6770833333vw 0.5208333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .select-field :global .ant-select-selection--single {
    height: 12.5vw;
    padding: 1.40625vw 0 0 1.875vw;
  }
}
.select-field :global .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 0;
  font-size: 0.9375vw;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: #000;
  line-height: 0.9375vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .select-field :global .ant-select-selection--single .ant-select-selection__rendered {
    font-size: 1.1458333333vw;
    line-height: 1.1458333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .select-field :global .ant-select-selection--single .ant-select-selection__rendered {
    line-height: 6.25vw;
    font-size: 4.21875vw;
  }
}
.select-field :global .ant-select-selection-selected-value {
  float: right;
}
.select-field :global .ant-select-open .ant-select-selection {
  box-shadow: none;
}
.select-field :global .ant-select-arrow {
  display: none;
}
.select-field :global .ant-select-arrow .ant-select-arrow {
  display: none !important;
}
.select-field :global .ant-select-selection {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #000;
  box-shadow: none;
}
.select-field :global .ant-select-selection__placeholder {
  top: 0;
  right: 0;
  height: auto;
  margin-top: 0;
  text-align: right;
  font-size: 0.9375vw;
  line-height: 0.9375vw;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .select-field :global .ant-select-selection__placeholder {
    font-size: 1.1458333333vw;
    line-height: 1.1458333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .select-field :global .ant-select-selection__placeholder {
    top: 1.40625vw;
    line-height: 4.21875vw;
    font-size: 4.21875vw;
  }
}
.select-field img {
  position: absolute;
  top: 0.4166666667vw;
  left: 0;
  z-index: 10;
  width: 1.25vw;
  height: 0.6770833333vw;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .select-field img {
    top: auto;
    bottom: 5.46875vw;
    width: 5.625vw;
    height: 3.25vw;
  }
}