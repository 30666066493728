@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.socials {
  position: relative;
  z-index: 51;
  display: flex;
  height: 100%;
  cursor: pointer;
}
.socials .social-button {
  display: inline-block;
  width: 1.6666666667vw;
  margin-right: 1.0416666667vw;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .socials .social-button {
    width: 4.21875vw;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button {
    width: 5vw;
    margin: 0 2.5vw;
  }
}
.socials .social-button svg {
  width: 100%;
  height: auto;
}
.socials .social-button img {
  width: 100%;
}
.socials .social-button:hover {
  opacity: 0.8;
}
.socials .overflowWrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap {
    display: flex;
    width: 46.25vw;
    justify-content: space-around;
  }
}
.socials .overflowWrap .icon-email {
  width: 1.875vw;
  margin-right: -0.4166666667vw;
  margin-bottom: -0.2083333333vw;
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap .icon-email {
    width: 5vw;
    margin-right: auto;
    margin-bottom: -0.625vw;
  }
}
@media only screen and (max-width: 767px) {
  .socials.minified {
    margin-right: 2.265625vw;
  }
}
.socials.minified.open .overflowWrap {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
@media only screen and (max-width: 767px) {
  .socials.minified.open .overflowWrap {
    position: absolute;
    top: -18.4375vw;
  }
}
.socials.minified .overflowWrap {
  position: relative;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .socials.minified .overflowWrap {
    position: absolute;
    top: -18.4375vw;
    left: -18.4375vw;
    display: flex;
    width: 46.25vw;
    height: 46.25vw;
    padding: 20.3125vw 3.90625vw 0 3.90625vw;
    padding-top: 20.3125vw;
    justify-content: space-around;
    transition: all 0.3s ease;
    background: #f4f5fa;
    border-radius: 23.125vw;
    box-shadow: 0 12px 40px 0 rgba(21, 37, 154, 0.23);
    transform: scale(0);
  }
}
.socials.minified .social-button {
  width: 9.375vw;
  margin: 0 0 0 0;
}
.socials.minified .icon {
  overflow: visible;
}
.socials.minified .icon-facebook {
  width: 9.375vw;
}
.socials.minified .icon-whatsapp {
  width: 9.375vw;
  margin-left: 0;
}
.socials.minified .icon-email {
  width: 4.375vw;
}
.socials.minified .icon-toggle {
  width: inherit;
  height: inherit;
}
.socials.minified .icon svg {
  width: 100%;
  height: 100%;
}
.socials.minified .toggleBtn {
  position: relative;
  z-index: 1;
  width: 6.71875vw;
  height: 6.71875vw;
  margin-left: -1.5625vw;
  border-radius: 50%;
  transition: 0.3s;
  overflow: visible;
}
.socials.minified .toggleBtn.open {
  width: 9.375vw;
  height: 9.375vw;
  margin: 0;
}
.socials.minified .toggleBtn svg {
  width: 100% !important;
  height: 100% !important;
}